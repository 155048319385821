<template>
  <div class="join-affiliate-component">
    <div class="container">
      <p class="description">
        Join VGANG's affiliate program and earn money through affiliate
        marketing. Share VGANG with your friends, followers and customers and
        get up to<span class="green-text">
          $1,800 commission per year for every referral.
        </span>
      </p>
      <div
        class="shopfiy-hero-container d-flex align-items-center justify-content-center pt-5"
      >
        <a
          target="_blank"
          class="hidden-on-web"
          href="https://calendly.com/vgang/vgang-for-suppliers"
        >
          <button
            class="d-flex align-items-center justify-content-center custom-button filled hero-button"
          >
            <img
              src="@/assets/icons/calendar_event.svg"
              style="margin-right: 5px"
              width="20px"
              height="20px"
              alt="shopify logo"
            />
            Schedule a call with us
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.join-affiliate-component {
  background: #edf7ee;
  padding: 75px;
  line-height: 28px;
  margin-bottom: 76px;

  @include md {
    padding: 55px;
    margin-bottom: 44px;
  }
  @include sm {
    padding: 25px;

    margin-bottom: 34px;
  }
  .green-text {
    color: #008f4a !important;
  }
  .description {
    font-size: 28px;
    line-height: 44px;
    color: #475467;
    font-weight: 600;
    text-align: center;
    @include md {
      font-size: 18px;
      line-height: 34px;
    }
    @include sm {
      font-size: 14px;
      line-height: 28px;
    }
  }
}
</style>

<template>
  <section class="write-comment">
    <div class="container">
      <div v-show="showTostMessage" class="notification_toast">
        <Transition
          name="slide-fade"
          :duration="{ enter: 500, leave: 800 }"
          appear
        >
          <div>
            <keep-alive>
              <Notification v-show="showTostMessage" :notifType="notifType">
                {{ tostMessage }}
              </Notification>
            </keep-alive>
          </div>
        </Transition>
      </div>
      <div class="row">
        <div class="form-wrapper mx-auto col-xl-7 col-lg-8 col-md-10">
          <span class="header d-flex justify-content-center align-items-center">
            Meetings Aren't Your Thing? No Problem!
          </span>
          <span class="sub-header d-flex align-items-center">
            No meetings required! Just submit the form below and we'll get back
            to you with lightning-fast responses.
          </span>
          <ValidationObserver ref="form" v-slot="{}">
            <form
              novalidate
              class="row justify-content-between"
              @submit.prevent="validate"
            >
              <div class="form-group">
                <ValidationProvider
                  name="name"
                  v-slot="{ errors }"
                  class="input-wrapper"
                >
                  <label for="name"> Subject </label>
                  <BaseSelectDrop
                    class="col-12 w-100"
                    :placeholder="'Sort type'"
                    :options="[...subjectList]"
                    :selectedOption="selectedSubject"
                    @selectOption="selectSubject($event)"
                  />

                  <span
                    class="error_animation error_messages"
                    v-show="errors[0] && !selectedSubject == undefined"
                  >
                    Please select the title
                  </span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                  class="input-wrapper"
                >
                  <label for="name">
                    How they call you <Br />

                    <span class="sub-label">
                      Your name or any name you wish we call you by that.
                    </span>
                  </label>
                  <input
                    id="name"
                    v-model="name"
                    name="name"
                    type="text"
                    class="form-control"
                    placeholder="Please enter your name"
                    :class="{ style_err: errors[0] }"
                  />
                  <span
                    class="error_animation error_messages"
                    v-show="errors[0]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="site"
                  rules="url"
                  v-slot="{ errors }"
                  class="input-wrapper"
                >
                  <label for="site"> Shop URL </label>
                  <div class="input-container d-flex align-items-center w-100">
                    <span class="http-label form-control">http://</span>
                    <input
                      name="site"
                      v-model="site"
                      dir="ltr"
                      type="email"
                      :class="{
                        'email-input-active': email,
                        style_err: errors[0],
                      }"
                      class="form-control site-input w-100"
                      placeholder="Please Enter you site address"
                    />
                  </div>

                  <span
                    class="error_animation error_messages"
                    v-show="errors[0]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                  class="input-wrapper"
                >
                  <label for="email"> Email address </label>
                  <input
                    name="email"
                    v-model="email"
                    dir="ltr"
                    type="email"
                    :class="{
                      'email-input-active': email,
                      style_err: errors[0],
                    }"
                    class="form-control"
                    placeholder="Please Enter you email"
                  />
                  <span
                    class="error_animation error_messages"
                    v-show="errors[0]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group col-12">
                <ValidationProvider
                  name="content"
                  rules="required"
                  v-slot="{ errors }"
                  class="input-wrapper"
                >
                  <label for="email"> Message </label>
                  <textarea
                    v-model="content"
                    name="content"
                    class="form-control"
                    placeholder="Enter a description..."
                    rows="6"
                    :class="{ style_err: errors[0] }"
                  ></textarea>
                  <span
                    class="error_animation error_messages"
                    v-show="errors[0]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="file-uploader-container">
                <span class="upload-ico material-symbols-outlined">
                  upload_file
                </span>

                <span class="title">{{
                  file ? fileName : " Add a screenshot or file"
                }}</span>
                <ValidationProvider
                  class="input-container"
                  ref="provider"
                  name="image"
                  v-slot="{ errors, validate }"
                >
                  <p>
                    <input class="w-100" type="file" @change="selected" />
                  </p>

                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="check-box-container">
                <input
                  type="checkbox"
                  id="keepUpdate"
                  name="keepUpdate"
                  value="Bike"
                  v-model="updateUserByEmail"
                />
                <label for="keepUpdate">
                  I'm eager to stay in the loop on this matter! Keep me updated
                  via email as things progress.
                </label>
              </div>
              <vue-recaptcha
                class="d-flex justify-content-center"
                ref="recaptcha"
                @verify="onRecaptchaVerified"
                :sitekey="siteKey"
              ></vue-recaptcha>
              <button
                :disabled="!isCaptchaValid"
                v-if="!loading"
                class="submit-btn"
              >
                Send My Message
              </button>
              <button
                v-else
                class="submit-btn"
                id="product-addtocart-button"
                title="Add to Bag"
                type="button"
              >
                <span id="loading">
                  <span>•</span>
                  <span>•</span>
                  <span>•</span>
                </span>
              </button>
              <p class="prefer-text text-center">
                Prefer to send us an email directly?
                <a class="green-text email-link" href="mailto:info@vgang.io">
                  info@vgang.io
                </a>
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import BaseSelectDrop from "@/components/bases/BaseSelectDrop.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Notification from "@/components/bases/Notification.vue";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseSelectDrop,
    VueRecaptcha,
    Notification,
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_SENDINBLUE_API_KEY,
      siteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
      selectedSubject: undefined,
      file: undefined,
      fileName: "",
      notifType: undefined,
      loading: false,
      updateUserByEmail: false,
      subjectList: [
        { id: "Report a Bug", value: "Report a Bug" },
        { id: "Request a Feature", value: "Request a Feature" },
        { id: "Request a Supplier", value: "Request a Supplier" },
        { id: "Payment Issues", value: "Payment Issues" },
        { id: "Other", value: "Other" },
      ],
      isCaptchaValid: false,
      formStatus: {
        success: false,
        error: false,
      },
      email: "",
      name: "",
      content: "",
      site: "",
      showTostMessage: false,
      tostMessage: "",
    };
  },

  methods: {
    selectSubject(event) {
      this.selectedSubject = event;
    },
    onRecaptchaVerified(response) {
      // This method will be called when the user successfully completes the reCAPTCHA challenge.
      // You can save the `response` to send it to your server for verification.
      if (response) this.isCaptchaValid = true;
    },

    disableMessage(event, statusName) {
      event.path[1].style.opacity = "0";
      setTimeout(() => {
        this.formStatus[statusName] = false;
      }, 300);
    },
    toggleToast(message, notifType) {
      this.showTostMessage = !this.showTostMessage;
      this.tostMessage = message;
      this.notifType = notifType;
      setTimeout(() => {
        this.showTostMessage = false;
      }, 3000);
    },
    async validate() {
      const result = await this.$refs.form.validate().then(async (success) => {
        // If client-side validation passes, move into this block.
        if (!success) {
          return false;
        }
        if (this.isCaptchaValid) {
          try {
            this.loading = !this.loading;
            const emailContent = {
              sender: { name: this.name, email: this.email },
              htmlContent: `${this.content} <br /><hr> <p>Site url: <strong> ${
                this.site
              }</strong></p> <h4 style="color: #45a9ff;">user${
                this.updateUserByEmail ? " want to" : " don't want to"
              }  update by email</h4>`,

              subject: this.selectedSubject.value,
              replyTo: { email: this.email, name: "Name" },

              to: [{ email: "info@vgang.io", name: "info" }],
              attachment: this.file
                ? [
                    {
                      name: this.fileName,
                      content: this.file,
                    },
                  ]
                : null,
            };

            const result = await fetch(
              "https://api.sendinblue.com/v3/smtp/email",
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  "api-key": process.env.VUE_APP_SENDINBLUE_API_KEY,
                },
                body: JSON.stringify(emailContent),
              }
            );

            this.toggleToast("You email sent successfully", "success");
            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.toggleToast(
              "Oops!...Something went wrong please try again",
              "failded"
            );
          }
        }
      });
    },
    async selected(event) {
      const file = event.target.files[0];
      this.fileName = file.name;

      if (file) {
        // Create a FileReader to read the file content as base64
        const reader = new FileReader();
        reader.onload = () => {
          // Store the base64-encoded file content in the component's data
          this.file = reader.result.split(",")[1];
        };
        reader.readAsDataURL(file);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.write-comment {
  margin-top: 21px;
  margin-bottom: 75px;
  @include sm {
    margin-top: 0;
    margin-bottom: 44px;
  }
  .header {
    font-weight: 700;
    line-height: 23px;
    color: #344054;
    font-size: 26px;
    margin-bottom: 34px;
    border: unset !important;
    text-align: center;
  }
  .sub-header {
    justify-content: center;
    color: #344054;
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
    text-align: center;
  }

  .sub-label {
    font-size: 13px;
    color: #667085;
  }
  .alert {
    opacity: 1;
    transition: all 0.2 ease-in;
    margin-right: auto !important;
    border-radius: 45px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .closebtn {
      font-size: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .alert-message {
      display: inline-flex;
      align-items: center;
    }
  }

  form {
    row-gap: 21px;
    margin-top: 44px;
    input {
      min-height: 44px;
    }

    input,
    textarea {
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      color: #667085;
    }
    input::placeholder,
    textarea::placeholder {
      color: #667085;
    }

    .submit-btn {
      max-width: 170px;
      margin: 18px auto;
      padding: 12px 0;
      @include flex-center;
      background: #008f4a;
      border-radius: 8px;
      border: none;
      font-weight: 500;
      color: #fff;
      font-size: 16px;

      transition: $transition;
      &:hover {
        background: #0bbb66;
        color: #fefefe;
      }
    }

    .email-input-active {
      direction: ltr;
      text-align: left;
    }
    label {
      font-weight: 600;
      color: #344054;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .input-wrapper {
      display: flex;
      flex-direction: column;
    }
    .http-label {
      font-size: 14px;
      font-weight: 400;
      color: #667085;
      padding: 10.5px 10px;
      border-radius: 5px;
      width: auto;
      border-right: unset;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }
    .site-input {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
    .prefer-text {
      font-size: 16px;
      font-weight: 600;
      color: #344054;
    }
    .email-link {
      font-size: 16px;
    }
  }

  .file-uploader-container {
    display: inline-flex;
    gap: 8px;
    border: 1px dashed #344054;
    padding: 14px;
    border-radius: 8px;
    position: relative;
    .input-container {
      position: absolute;
      inset: 0;
      opacity: 0;
    }
    .upload-icon {
      font-size: 20px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      color: #344054;
      display: flex;
      align-items: center;
    }
  }
  .check-box-container {
    display: flex;
    align-items: center;
    gap: 8px;
    input {
      cursor: pointer;
      accent-color: #008f4a;
      width: 18px;
    }
    label {
      margin-bottom: 0;
    }
  }
}
@include lg {
  .write-comment .form-wrapper {
    border-radius: 45px;
    padding: 34px 55px;
  }
}
@include md {
  .write-comment .header {
    font-size: 18px;
  }
  .write-comment form input,
  .write-comment form textarea {
    font-size: 12px;
  }
  .write-comment form {
    row-gap: 21px;
  }

  .error_messages {
    margin-top: 0 !important;
  }
}
@include sm {
  .write-comment .form-wrapper {
    padding: 28px 28px;
    padding-top: 0;
  }

  .error_messages {
    display: inline-block;
    padding: 8px 5px;
  }
  .alert {
    padding: 0.875rem;
  }
  .write-comment .alert .closebtn {
    font-size: 24px;
  }
}
.error_messages {
  display: inline-block;
  padding: 5px;
  color: #ff5d5e;
  margin-top: 7px;
  font-size: 14px;
  font-weight: 500;
}
.error_animation {
  animation: errorFadeInDown 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.style_err {
  border: 1px solid#ff5d5e;
}
@keyframes errorFadeInDown {
  0% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  59% {
    opacity: 1;
    transform: skewX(20deg);
  }
  70%,
  90% {
    transform: skewX(-20deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
button.button {
  height: 40px;
  line-height: 38px;
  color: #fff;
  background-color: #000;
  border: 0 none;
  vertical-align: middle;
  width: 270px;
  font-size: 14px;
}
#loading {
  text-align: center;
}
#loading span:not(:last-child) {
  margin-right: 5px;
}
#loading span {
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: opacity;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
}
#loading span:nth-child(2) {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
}
#loading span:not(:last-child) {
  margin-right: 5px;
}
#loading span:nth-child(3) {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
button:disabled {
  cursor: not-allowed;
}
</style>

<template>
  <div class="contact-hero">
    <div class="container">
      <div class="row text-center">
        <h2 class="hero-title">
          Get in Touch and Let's Make Something Great Together!
        </h2>

        <p class="description">
          We're here to answer your questions and <br />
          listen to your feedback.
        </p>
      </div>
      <BookCall />
    </div>
  </div>
</template>

<script>
import BookCall from "./BookCall.vue";
export default { components: { BookCall } };
</script>

<style lang="scss" scoped></style>

<template>
  <section class="contact-section">
    <Hero />
    <JoinAffiliate class="hidden-on-web" />
    <ContactForm />
  </section>
</template>

<script>
import JoinAffiliate from "../components/affiliates/JoinAffiliate.vue";
import Hero from "../components/contact/Hero.vue";
import ContactForm from "../components/contact/ContactForm.vue";
export default {
  name: "Contact",
  components: { Hero, ContactForm, JoinAffiliate },
};
</script>

<style lang="scss" scoped></style>

<template>
  <section class="book-call-section mb-10">
    <div class="container py-5 px-3">
      <div class="row">
        <div class="col-sm-12">
          <p class="section-paragraph hidden-on-mobile">
            Book a call and we'll answer all your questions and give you a
            personalized demo. Let's make it efficient!
          </p>
        </div>

        <div
          class="shopfiy-hero-container d-flex align-items-center justify-content-center pt-5"
        >
          <a href="https://calendly.com/vgang/vgang-for-suppliers">
            <button
              class="d-flex align-items-center justify-content-center custom-button filled hero-button"
            >
              <img
                src="@/assets/icons/calendar_event.svg"
                style="margin-right: 5px"
                width="20px"
                height="20px"
                alt="shopify logo"
              />
              Schedule a call with us
            </button>
          </a>
        </div>

        <div class="benefits hidden-on-web">
          <h3 class="benefits-title">Benefits of VGANG affiliate program</h3>
          <p>
            Join VGANG Affiliate Program and Help Spread the Mission of
            Sustainability While Earning Commission.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isScheduleCall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.book-call-section {
  margin-top: 55px;
  margin-bottom: 87px !important;
  @include lg {
    margin-bottom: 55px !important;
  }
  @include md {
    margin-top: 0;
    margin-bottom: 55px !important;
  }
  @include sm {
    margin-bottom: 34px !important;
  }

  .container {
    background-color: #edf7ee;
    border: 2px solid #008f4a;
    width: 50vw;
    border-radius: 18px;

    @include md {
      padding: 0 !important;
      background-color: unset;
      border: unset;
      width: 92%;
    }
  }

  .shopfiy-hero-container {
    row-gap: 13px;

    a {
      img {
        width: 20px;
        height: 20px;
      }
    }
    @include md {
      flex-direction: column;
    }
    .custom-button {
      margin-right: 44px;
      font-size: 14px;
      @include md {
        max-width: unset;
        margin: 0;
        justify-content: center;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  .section-paragraph {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #344054;
    text-align: center;
    @include md {
      font-size: 16px;
    }
    @include sm {
      font-size: 14px !important;
    }
  }
  .benefits {
    .benefits-title {
      font-size: 24px;
      font-weight: 800;
      color: #344054;
      padding-top: 34px;
      padding-bottom: 14px;
      @include sm {
        font-size: 20px;
      }
    }
    P {
      font-weight: 600;
      color: #344054;
      margin-bottom: 10px;
      font-size: 18px;
      @include sm {
        font-size: 16px;
      }
    }
  }
}
</style>
